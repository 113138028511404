import { useQuery } from "@tanstack/react-query";

import api from "@/api/axios";
import Button from "@/components/ButtonLink";
import { pluralize } from "@/helpers/utils";

import type { PurchasedSticker } from "@/types/sticker";

export default function StickerPlacementButton({ onClick }: { onClick: () => void }) {
  const { data: purchasedStickers, error: purchasedStickersError } = useQuery({
    queryKey: ["purchased-stickers"],
    queryFn: async () => {
      const { data } = await api.get<PurchasedSticker[]>("/v1/purchased_stickers");
      return data;
    },
    retry: false,
  });

  if (!purchasedStickers || purchasedStickersError || purchasedStickers?.length === 0) {
    return null;
  }

  return (
    <Button
      Icon={
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.5633 2.41253L19.5307 12.3799H19.3757L19.2009 12.4479C16.562 13.4738 13.5395 12.5221 11.4291 10.4687C9.3266 8.42303 8.33985 5.49333 9.48523 2.76727L9.5633 2.58146V2.41253Z"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M1 11.0611C1 6.27952 4.35696 2.28013 8.84269 1.29423L20.7669 13.2184C19.781 17.7042 15.7816 21.0611 11 21.0611C5.47715 21.0611 1 16.584 1 11.0611Z"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M17.3739 4.02637V2.69336H23.6971V4.02637H17.3739ZM19.7938 6.71289V0H21.2772V6.71289H19.7938Z"
            fill="currentColor"
          />
        </svg>
      }
      type="primary"
      label={`${purchasedStickers.length} ${pluralize("sticker", purchasedStickers.length)} left`}
      onClick={onClick}
    />
  );
}
