import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { differenceInHours } from "date-fns";
import { navigate } from "vike/client/router";
import toast from "react-hot-toast";
import { useData } from "vike-react/useData";
import { usePageContext } from "vike-react/usePageContext";
import { useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon, ShareIcon } from "@heroicons/react/24/solid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import api from "@/api/axios";
import Button from "@/components/ButtonLink";
import { errorMessageExtractor } from "@/helpers/error-message";
import FollowButton from "@/components/FollowButton";
import { handleShare } from "@/helpers/handle-share";
import { Link } from "@/components/Link";
import { pluralize } from "@/helpers/utils";
import ProfilePicture from "@/components/ProfilePicture";
import { TwoColumn } from "@/layouts/two-column";
import { useUser } from "@/hooks/useUser";

import StickerPlacementButton from "./StickerPlacementButton";
import StickerPlacementModal from "./StickerPlacementModal";
import StickerPurchaser from "./StickerPurchaser";
import UserWishlists from "./UserWishlists";

import type { Data } from "./+data.js";
import type { EmbeddedUser } from "@/types/user";
import type { ErrorResponse } from "@/types/error";
import type { PurchasedSticker } from "@/types/sticker";

const isUser = (data: Data): data is EmbeddedUser => !("error" in data);

export default function Profile() {
  const pageContext = usePageContext();
  const slug = pageContext.routeParams.slug;

  const { user: currentUser } = useUser();
  const queryClient = useQueryClient();

  const [isPlacingSticker, setIsPlacingSticker] = useState(false);
  const [selectedStickerId, setSelectedStickerId] = useState<number | null>(null);
  const [stickerPosition, setStickerPosition] = useState<{ x: number; y: number } | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [touchStartPosition, setTouchStartPosition] = useState<{ x: number; y: number } | null>(null);
  const [isValidPlacement, setIsValidPlacement] = useState(true);

  const user = useData<Data>();

  const { data: userAttachedStickers } = useQuery({
    queryKey: ["user-attached-stickers", isUser(user) ? user.id : null],
    queryFn: async () => {
      const { data } = await api.get<PurchasedSticker[]>(
        `/v1/users/${isUser(user) ? user.id : null}/attached_stickers`
      );
      return data;
    },
    enabled: isUser(user),
    retry: false,
  });

  const attachSticker = useMutation({
    mutationFn: async (params: { userId?: string; identifier: number; positionX: number; positionY: number }) => {
      if (!params.userId) {
        throw new Error("Current user not found");
      }

      const { data } = await api.post(`/v1/users/${params.userId}/attached_stickers`, {
        identifier: params.identifier,
        position_x: params.positionX,
        position_y: params.positionY,
      });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["purchased-stickers"] });
      queryClient.invalidateQueries({ queryKey: ["user-attached-stickers", isUser(user) ? user.id : undefined] });

      toast.success("Sticker placed successfully!", {
        duration: 3000,
        position: "bottom-right",
        style: {
          background: "#333",
          color: "#fff",
        },
      });
    },
    onError: (e: ErrorResponse) => {
      const { message } = errorMessageExtractor(e);

      toast.error(message, {
        duration: 3000,
        position: "bottom-right",
        style: {
          background: "#333",
          color: "#fff",
        },
      });
    },
  });

  const handleMessage = async () => {
    try {
      const { data } = await api.post("/v1/chat_rooms", {
        initial_recipient_id: isUser(user) ? user.id : null,
      });
      navigate(`/chat/${data.id}`);
    } catch (error) {
      console.error("Failed to create chat room:", error);
      // You might want to show an error toast/notification here
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const checkCollision = (position: { x: number; y: number }) => {
    if (!userAttachedStickers) return true;

    // Define the size of the "collision box" (as percentage of container)
    const collisionThreshold = 8;

    return !userAttachedStickers.some((sticker) => {
      const dx = Math.abs(sticker.position_x - position.x);
      const dy = Math.abs(sticker.position_y - position.y);
      return dx < collisionThreshold && dy < collisionThreshold;
    });
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    if (!selectedStickerId) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    const newPosition = { x, y };
    setIsValidPlacement(checkCollision(newPosition));
    setStickerPosition(newPosition);
  };

  const handleStickerSelect = (id: number) => {
    setSelectedStickerId(id);
    setStickerPosition({ x: 50, y: 50 }); // Center initially
    setIsEditMode(true);
  };

  const handleConfirmPlacement = () => {
    if (selectedStickerId && stickerPosition && isValidPlacement) {
      attachSticker.mutate({
        userId: isUser(user) ? user.id : undefined,
        identifier: selectedStickerId,
        positionX: stickerPosition.x,
        positionY: stickerPosition.y,
      });

      // Reset state
      setSelectedStickerId(null);
      setStickerPosition(null);
      setIsEditMode(false);
      setIsValidPlacement(true);
    }
  };

  const handleCancelPlacement = () => {
    setSelectedStickerId(null);
    setStickerPosition(null);
    setIsEditMode(false);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!selectedStickerId) return;

    const touch = e.touches[0];
    setTouchStartPosition({ x: touch.clientX, y: touch.clientY });
    setIsDragging(true);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!selectedStickerId || !touchStartPosition) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const touch = e.touches[0];

    const x = ((touch.clientX - rect.left) / rect.width) * 100;
    const y = ((touch.clientY - rect.top) / rect.height) * 100;

    // Constrain the position to stay within the bounds (0-100%)
    const boundedX = Math.max(0, Math.min(100, x));
    const boundedY = Math.max(0, Math.min(100, y));

    const newPosition = { x: boundedX, y: boundedY };
    setIsValidPlacement(checkCollision(newPosition));
    setStickerPosition(newPosition);
    e.preventDefault();
  };

  const handleTouchEnd = () => {
    setTouchStartPosition(null);
    setIsDragging(false);
  };

  if ("error" in user) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen gap-6">
        <div className="card w-96 bg-base-100">
          <div className="card-body items-center text-center">
            <XCircleIcon className="w-24 h-24 text-error" />
            <h2 className="card-title text-2xl">Profile Not Found</h2>
            <p className="text-base-content/70">We couldn't find a profile for @{slug}</p>
            <div className="card-actions justify-end mt-4">
              <Link to="/dreamers" className="btn btn-outline btn-sm">
                Discover
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!user) return null;

  return (
    <TwoColumn.Root>
      <TwoColumn.Main>
        {currentUser && (
          <div className="mb-4">
            <StickerPurchaser />
          </div>
        )}

        <div className="card bg-base-100 mt-0 pt-0 rounded-xl">
          <div className="hero-content flex-col py-4 lg:py-8">
            <div className="avatar w-full mx-auto flex items-center justify-center">
              <div
                className={`w-full md:max-w-xl aspect-square rounded-xl relative ${
                  isEditMode ? `border-4 border-dashed ${isValidPlacement ? "border-primary/40" : "border-error"}` : ""
                } ${isDragging ? (isValidPlacement ? "border-primary/80" : "border-error") : ""}`}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrag}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                <ProfilePicture isCloudShown url={user.profile_picture_url} displayName={user.display_name} />

                {/* Render existing attached stickers */}
                {userAttachedStickers?.map((sticker) => {
                  const hoursUntilExpiration = differenceInHours(new Date(sticker.expired_at), new Date());

                  // Calculate tooltip position based on both X and Y coordinates
                  let tooltipPosition = "tooltip-bottom";
                  if (sticker.position_y > 70) {
                    tooltipPosition = "tooltip-top";
                  }
                  if (sticker.position_x < 20) {
                    tooltipPosition = "tooltip-right";
                  } else if (sticker.position_x > 80) {
                    tooltipPosition = "tooltip-left";
                  }

                  return (
                    <div
                      key={sticker.id}
                      className={`absolute tooltip tooltip-primary ${tooltipPosition} transform -translate-x-1/2 -translate-y-1/2`}
                      data-tip={`${sticker.purchaser.display_name} - ${hoursUntilExpiration} ${pluralize("hour", hoursUntilExpiration)} left`}
                      style={{
                        left: `${sticker.position_x}%`,
                        top: `${sticker.position_y}%`,
                      }}
                    >
                      <img
                        src={`/images/stickers/${sticker.identifier}.svg`}
                        alt="Attached sticker"
                        className="object-contain"
                        style={{
                          width: "4rem",
                          height: "auto",
                          maxHeight: "6rem",
                        }}
                      />
                    </div>
                  );
                })}

                {/* Render the selected sticker being placed */}
                {selectedStickerId && stickerPosition && (
                  <img
                    src={`/images/stickers/${selectedStickerId}.svg`}
                    alt="Selected sticker"
                    className="absolute transform -translate-x-1/2 -translate-y-1/2 cursor-move object-contain touch-none"
                    style={{
                      left: `${stickerPosition.x}%`,
                      top: `${stickerPosition.y}%`,
                      width: "4rem",
                      height: "auto",
                      maxHeight: "6rem",
                    }}
                    draggable
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                  />
                )}

                <div className="absolute bottom-3 right-3">
                  {isEditMode ? (
                    <div className="flex justify-center gap-2">
                      <Button
                        type="primary"
                        label="Place Sticker!"
                        onClick={handleConfirmPlacement}
                        disabled={!isValidPlacement}
                      />
                      <Button type="secondary" label="Cancel" onClick={handleCancelPlacement} />
                    </div>
                  ) : (
                    <StickerPlacementButton onClick={() => setIsPlacingSticker(true)} />
                  )}
                </div>
              </div>
            </div>

            <div className="w-full text-center mb-8">
              <h1 className="text-2xl font-bold mb-1 flex items-center justify-center gap-1">
                {user.display_name}
                {user.verified && <CheckBadgeIcon className="w-6 h-6 text-primary" />}
              </h1>
              {user.biography && (
                <p className="text-base text-base-content/80 max-w-lg mx-auto mb-6">{user.biography}</p>
              )}
              <div className="flex justify-center gap-8 mb-6">
                <div className="text-center">
                  <p className="text-lg font-bold">{user.followers_count}</p>
                  <p className="text-sm text-primary">Followers</p>
                </div>
                {/* Add more stats here if needed */}
              </div>
              <div className="mt-6 flex flex-col sm:flex-row w-full sm:flex-wrap justify-center gap-4">
                <FollowButton slug={user.slug || user.id} />
                <Button
                  Icon={<EnvelopeIcon className="h-5 w-5 mr-2" />}
                  type="primary"
                  label="Message"
                  onClick={handleMessage}
                />
                <Button
                  Icon={<ShareIcon className="h-5 w-5 mr-2" />}
                  type="primary"
                  label="Share"
                  onClick={handleShare}
                />
              </div>
            </div>
          </div>
        </div>

        <StickerPlacementModal
          isOpen={isPlacingSticker}
          onClose={() => setIsPlacingSticker(false)}
          onStickerSelect={handleStickerSelect}
        />
      </TwoColumn.Main>

      <TwoColumn.Aside>
        <UserWishlists userId={user.id} />
      </TwoColumn.Aside>
    </TwoColumn.Root>
  );
}
