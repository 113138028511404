import { useState } from "react";

import Button from "@/components/ButtonLink";
import RandomStickers from "@/components/RandomStickers";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onStickerSelect: (stickerId: number) => void;
};

export default function StickerPlacementModal({ isOpen, onClose, onStickerSelect }: Props) {
  const [selectedStickerId, setSelectedStickerId] = useState<number | null>(null);

  const handleStickerSelect = () => {
    if (selectedStickerId) {
      onStickerSelect(selectedStickerId);
      onClose();
    }
  };

  const getImageClassName = (id: number) => `
    w-16 h-16 
    cursor-pointer 
    transition-all 
    hover:scale-110 
    p-1 
    rounded-lg
    ${selectedStickerId === id ? "bg-primary/10 ring-2 ring-primary scale-110" : ""}
  `;

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]"
      onClick={() => onClose()}
    >
      <div className="modal-box bg-base-100 p-6 rounded-lg max-w-md w-full" onClick={(e) => e.stopPropagation()}>
        <h3 className="font-bold text-2xl bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
          Select a Sticker
        </h3>
        <p className="text-sm text-gray-600 mb-6">
          After you select a sticker, you can drag it anywhere on this dreamer's profile picture!
        </p>

        <RandomStickers
          count={12}
          wrapperClassName="grid grid-cols-4 gap-4"
          imageClassName={getImageClassName}
          onStickerClick={(id) => setSelectedStickerId(id)}
        />

        <div className="modal-action mt-6">
          <Button label="Cancel" type="secondary" onClick={onClose} />
          <Button label="Select Sticker" type="primary" onClick={handleStickerSelect} disabled={!selectedStickerId} />
        </div>
      </div>
    </div>
  );
}
