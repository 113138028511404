import { useQuery } from "@tanstack/react-query";

import api from "@/api/axios";
import { Link } from "@/components/Link";

import type { Wishlist } from "@/types/wishlist";

type Props = {
  userId: string;
};

export default function UserWishlists({ userId }: Props) {
  const { data: wishlists, isLoading } = useQuery({
    queryKey: ["user-wishlists", userId],
    queryFn: async () => {
      const { data } = await api.get<Wishlist[]>(`/v1/users/${userId}/wishlists`);
      return data;
    },
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <span className="loading loading-spinner loading-lg text-primary"></span>
      </div>
    );
  }

  const renderWishlistCard = (wishlist: Wishlist) => {
    return (
      <div key={wishlist.id} className="flex items-stretch">
        <Link to={`/wishlists/${wishlist.id}`} className="flex-1 transition-all hover:bg-base-200">
          <div className="flex gap-3 items-center">
            <div className="w-28 h-28 flex-shrink-0 relative rounded-lg">
              {wishlist.cover_photo_url ? (
                <img src={wishlist.cover_photo_url} className="w-full h-full object-contain p-2" aria-hidden="true" />
              ) : (
                <img
                  src={`/images/wishlist-themes/${wishlist.theme}.svg`}
                  className="w-full h-full object-contain p-2"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="flex flex-col gap-0.5 min-w-0">
              <h3 className="font-medium truncate">{wishlist.name}</h3>
              <div className="text-xs text-base-content/70 line-clamp-2">{wishlist.description}</div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div className="bg-base-100 p-4">
      <h2 className="card-title text-base-content/60 text-lg">Wishlists</h2>
      <div className="divide-y divide-base-200 overflow-y-auto">{wishlists?.map(renderWishlistCard)}</div>

      {wishlists?.length === 0 && (
        <div className="hero py-8">
          <div className="hero-content text-center">
            <div className="max-w-md">
              <div className="text-4xl mb-4">🎁</div>
              <h3 className="text-base font-bold mb-2">No Wishlists Yet</h3>
              <p className="text-sm text-base-content/70">This dreamer hasn't created any wishlists yet.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
