import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UserMinusIcon, UserPlusIcon } from "@heroicons/react/24/solid";

import api from "@/api/axios";
import Button from "@/components/ButtonLink";

import type { EmbeddedUser } from "@/types/user";

export default function FollowButton({ slug }: { slug: string }) {
  const queryClient = useQueryClient();

  const { data: user } = useQuery({
    queryKey: ["user", slug],
    queryFn: async () => {
      const { data } = await api.get<EmbeddedUser>(`/v1/users/${slug}`);
      return data;
    },
    retry: false,
  });

  const { mutate: followUser } = useMutation({
    mutationFn: async (userId: string) => {
      const { data } = await api.post(`/v1/users/${userId}/follow`);
      return data;
    },
    onMutate: async () => {
      // Update profile cache if it exists - we should check both user.slug and user.id
      queryClient.setQueryData(["user", user?.slug], (oldData: EmbeddedUser | undefined) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          is_following: true,
          followers_count: oldData.followers_count + 1,
        };
      });

      queryClient.setQueryData(["user", user?.id], (oldData: EmbeddedUser | undefined) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          is_following: true,
          followers_count: oldData.followers_count + 1,
        };
      });
    },
  });

  const { mutate: unfollowUser } = useMutation({
    mutationFn: async (userId: string) => {
      const { data } = await api.post(`/v1/users/${userId}/unfollow`);
      return data;
    },
    onMutate: async () => {
      // Update profile cache if it exists - we should check both user.slug and user.id
      queryClient.setQueryData(["user", user?.slug], (oldData: EmbeddedUser | undefined) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          is_following: false,
          followers_count: oldData.followers_count - 1,
        };
      });

      queryClient.setQueryData(["user", user?.id], (oldData: EmbeddedUser | undefined) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          is_following: false,
          followers_count: oldData.followers_count - 1,
        };
      });
    },
  });

  if (!user) return null;

  return user.is_following ? (
    <Button
      Icon={<UserMinusIcon className="h-5 w-5" />}
      type="secondary"
      label="Unfollow"
      onClick={() => unfollowUser(user.id)}
    />
  ) : (
    <Button
      Icon={<UserPlusIcon className="h-5 w-5" />}
      type="primary"
      label="Follow"
      onClick={() => followUser(user.id)}
    />
  );
}
