import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { navigate } from "vike/client/router";
import { useChord } from "@/hooks/useChord";

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import api from "@/api/axios";
import Button from "@/components/ButtonLink";

import type { DetailedProduct } from "@/types/product";
import RandomStickers from "@/components/RandomStickers";

export default function StickerPurchaser() {
  const chord = useChord();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPackId, setSelectedPackId] = useState<string | null>(null);

  const { data: stickerData } = useQuery({
    queryKey: ["products-stickers"],
    queryFn: async () => {
      const { data } = await api.get<DetailedProduct[]>("/v1/products/stickers");
      return data;
    },
    enabled: isOpen,
    staleTime: 10 * 60 * 1000,
    retry: false,
  });

  const fivePackSticker = stickerData?.find((sticker) => sticker.vendor_product_id === "dreamvault-five-stickers-pack");
  const tenPackSticker = stickerData?.find((sticker) => sticker.vendor_product_id === "dreamvault-ten-stickers-pack");

  const handleStickerPackSelection = (id: string | null) => {
    setSelectedPackId(id);
    const stickerPack = stickerData?.find((sticker) => sticker.id === id);

    chord.trackProductClicked({
      cart: {},
      product: {
        product: {
          id: stickerPack?.id ?? "",
          sku: stickerPack?.vendor_product_id ?? "",
          name: stickerPack?.title ?? "",
          price: stickerPack?.variants[0].price_dollars ?? 0,
          categories: stickerPack?.categories ?? [],
          brand: "Dreamvault",
          description: stickerPack?.title ?? "",
        },
        quantity: 1,
      },
    });
  };

  const handleCheckoutClick = () => {
    if (!selectedPackId) return;
    const stickerPack = stickerData?.find((sticker) => sticker.id === selectedPackId);

    const product = {
      id: selectedPackId,
      vendor_product_id: stickerPack?.vendor_product_id ?? "",
      name: stickerPack?.title ?? "",
      sku: stickerPack?.vendor_product_id ?? "",
      price: stickerPack?.variants[0].price_dollars ?? 0,
      categories: stickerPack?.categories ?? [],
      brand: "Dreamvault",
      description: stickerPack?.title ?? "",
      quantity: 1,
    };

    const cart = {
      products: [product],
    };

    chord.trackProductAdded({
      cart,
      product: { product, quantity: 1, variantId: stickerPack?.variants[0].id },
    });

    navigate(`/checkout?item_id=${selectedPackId}&item_type=Products::Sticker&gateway=stripe`);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(true)}
        className="w-full bg-base-100 hover:bg-base-200 transition-all rounded-xl p-4 group"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <RandomStickers
              count={1}
              wrapperElement="span"
              imageClassName="w-14 h-14 group-hover:scale-110 transition-transform"
            />

            <div className="text-left">
              <div className="font-bold text-lg">Purchase Stickers</div>
              <div className="text-sm opacity-75">Support and interact with your favorite dreamers</div>
            </div>
          </div>
          <div className="text-primary">
            <ChevronRightIcon className="w-6 h-6 group-hover:translate-x-1 transition-transform" />
          </div>
        </div>
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]"
          onClick={() => setIsOpen(false)}
        >
          <div className="modal-box bg-base-100 p-6 rounded-lg max-w-md w-full" onClick={(e) => e.stopPropagation()}>
            <div className="flex items-center gap-3 mb-4">
              <h3 className="font-bold text-2xl bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                Spread the Joy with Stickers!
              </h3>
            </div>

            <p className="text-sm text-gray-600 mb-6">
              Purchase bundles of stickers and place them on any dreamer profile. Your sticker will be visible for all
              to see for <span className="font-bold">72 hours</span>!
            </p>

            <div className="space-y-4">
              <div
                className={`card ${
                  selectedPackId === fivePackSticker?.id
                    ? "bg-primary bg-opacity-10 border-2 border-primary"
                    : "bg-base-200"
                } transition-all cursor-pointer`}
                onClick={() => handleStickerPackSelection(fivePackSticker?.id || null)}
              >
                <div className="card-body">
                  <div className="flex justify-between items-center">
                    <div>
                      <h4 className="font-semibold">{fivePackSticker?.title}</h4>
                    </div>
                    <div className="text-xl font-bold">${fivePackSticker?.variants[0].price_dollars}</div>
                  </div>
                </div>
              </div>

              <div
                className={`card ${
                  selectedPackId === tenPackSticker?.id
                    ? "bg-primary bg-opacity-10 border-2 border-primary"
                    : "bg-base-200"
                } transition-all cursor-pointer`}
                onClick={() => handleStickerPackSelection(tenPackSticker?.id || null)}
              >
                <div className="card-body">
                  <div className="flex justify-between items-center">
                    <div>
                      <h4 className="font-semibold">{tenPackSticker?.title}</h4>
                    </div>
                    <div className="text-xl font-bold">${tenPackSticker?.variants[0].price_dollars}</div>
                  </div>
                </div>
              </div>
            </div>

            <RandomStickers
              count={8}
              wrapperClassName="grid grid-cols-4 gap-2 my-6"
              imageClassName="w-16 h-16 object-contain"
            />

            <div className="modal-action mt-6">
              <Button
                label="Proceed to Checkout"
                type="primary"
                className="w-full"
                disabled={!selectedPackId}
                onClick={handleCheckoutClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
